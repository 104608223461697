import {t} from 'i18next';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DateTime, Duration} from 'luxon';
import {useHistory} from 'react-router';
import {CARD} from '../../constants/cards';
import {ROUTES} from '../../constants/routes';
import {addDataToCard, enableCard, updateCard, loadBooking, updateErrorCard, updateCardAndData, updateConfirmationCard} from '../../store/cards';
import {updateDetailsBookingData} from '../../store/bookingsOverview/bookings';
import {getAreaNameBasedOnId, getOfficeBasedOnId, getTeakTypeObj, updateActionSheet} from '../../store/auth';
import {changeArea, changeDesk, changeRoom} from '../../store/reportProblem';
import {getTeakEntitiesFull} from '../../functions/getTeakEntitiesFull';
import {bookingStatus} from '../../constants/bookingStatus';
import {addRepeatBookingObject, showBookingOnMap, updateAttendeeList, updateEditBookingView} from '../../store/bookingProcess/entitiesBooking';
import {enableQuestionnairePage} from '../../store/dashboard';
import {limit} from '../../constants/limit';
import {filteredAttendeeList} from '../BookingsOverview/BookingDetails';
import {updateCheckedList} from '../../store/settings';
import {TEAK_TYPE_NAMES} from '../../constants/teakTypeNames';
import {BOTTOM_MAP_CARDS} from '../../constants/bottomCardTypes';
import {confirmActionsMap} from '../Cards/Common/ConfirmAction/confirmActions';

// * Component holding the action menu shown when users select a booking in Bookings Overview page.
const BookingOptionsMenu = (props) => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.auth.data.actionSheetData);
	const history = useHistory();
	const areaId = data?.entity?.area._id ? data?.entity?.area._id : data?.entity?.area;
	const areaName = useSelector(getAreaNameBasedOnId(areaId)) || data?.entity?.area?.name;
	const teakEntityName = data?.entity?.name;
	const areasFromStore = useSelector((state) => state.auth.data.areas);
	const featureTranslations = useSelector((state) => state.auth.data.featureTranslations);
	const lastCompleted = useSelector((state) => state.dashboard.data.plusStatusLastCompleted);
	const validCeylon = useSelector((state) => state.dashboard.data.validCeylon);
	const userData = useSelector((state) => state.auth.data.userData);
	const teakFeaturesFromStore = useSelector((state) => state.auth.data.teakFeatures);
	const officeId = data?.entity?.office._id ? data?.entity?.office?._id : data?.entity?.office;
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const officeObject = useSelector(getOfficeBasedOnId(officeId));
	const authData = useSelector((state) => state.auth.data);
	const [isUserCheckedIn, setIsUserCheckedIn] = useState(true);
	const [timeLeftUntilCheckin, setTimeLeftUntilCheckin] = useState(null);
	const [checkinButtonDisabled, setCheckinButtonDisabled] = useState(false);
	const bookingDataStatus = data?.booking?.status;
	const teakTypeForOffice = authData?.teakTypeArray?.find((teakType) => teakType.office === officeId);
	const isMemberHost = data?.booking?.member?.id === userData?._id;
	const teakTypeObj = useSelector(getTeakTypeObj(data?.entity?.teakType));
	// menu item functions
	const redirectToBookingDetails = (e) => {
		e.stopPropagation();
		props.closeActionSheet();
		const filteredAttendees = filteredAttendeeList(data?.booking.attendees, data?.booking, userData);
		dispatch(updateAttendeeList(filteredAttendees));
		dispatch(updateDetailsBookingData({booking: data.booking, entity: data.entity}));
		console.log('data.booking', data?.booking);
		history.push(ROUTES.SEE_DETAILS_OF_BOOKING);
	};

	const handleShowOnMap = () => {
		props.closeActionSheet();
		history.push(ROUTES.BOOKING_PAGE);
		dispatch(showBookingOnMap(data?.booking, data?.entity, BOTTOM_MAP_CARDS.ownBooking));
	};

	const handleCheckOutClick = () => {
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.CHECKOUT,
				{
					headerKey: 'common.checkout',
					infoKey: `${t('checkOutCard.confirmCheckout', {
						entityName: `<span class="bold">${teakEntityName}</span>`,
					})}`,
					confirmButtonKey: 'checkOutCard.confirmCheckoutButton',
				},
				data,
			),
		);
	};

	const handleForwardClick = () => {
		dispatch(updateCheckedList([]));
		history.push(ROUTES.PROFILE_PAGE + ROUTES.COLLEAGUE_LIST, {addAttendee: true, forwardMeetingId: data?.booking?._id});
	};

	const handleCheckInClick = (e) => {
		if (!teakTypeObj?.options?.bookings?.biroButtonCheckIn?.enabled && timeLeftUntilCheckin === 0) {
			dispatch(enableCard(true));
			dispatch(updateCardAndData(CARD.CHECKIN_DISABLED));
			return;
		}

		if (checkinButtonDisabled) {
			e.stopPropagation();
			return;
		}

		if (data.booking === null) {
			dispatch(enableCard(true));
			dispatch(updateCardAndData(CARD.CONFIRM_CHECKIN, data));
		} else {
			if (officeObject.ceylon?.rule === '2G+') {
				if (!lastCompleted || DateTime.fromISO(lastCompleted).plus({hours: 24}).toUTC() < DateTime.now().toUTC()) {
					dispatch(updateErrorCard(CARD.MAIN_ERROR, 'errorMessages.invalidPlusTest'));
					return;
				}
			}
			if (officeObject?.biro?.healthQuestionnaire?.enabled) {
				if (
					officeObject?.biro?.healthQuestionnaire?.promptCompletion === 'prior' ||
					officeObject?.biro?.healthQuestionnaire?.promptCompletion === undefined
				) {
					const currentUserData = authData?.userData;
					const healthQuestionnaireCompletions = currentUserData?.biro?.healthQuestionnaireCompletions;
					if (healthQuestionnaireCompletions?.length > 0) {
						let validityOfHQforSelectedOffice = authData?.selectedOffice?.biro?.healthQuestionnaire?.validity;
						if (validityOfHQforSelectedOffice === undefined) validityOfHQforSelectedOffice = 172800000; //if undefined the default is 48 hrs in ms
						const lastHQCompletion = healthQuestionnaireCompletions[healthQuestionnaireCompletions.length - 1];
						if (DateTime.now().toUTC() - DateTime.fromISO(lastHQCompletion).toUTC() >= validityOfHQforSelectedOffice) {
							dispatch(enableCard(false));
							dispatch(updateActionSheet(false));
							dispatch(enableQuestionnairePage(true, data.booking, 'nextBooking'));
							history.push(ROUTES.QUESTIONNER_PAGE);
						} else {
							dispatch(
								loadBooking(
									{
										...data.booking,
										teakEntity: data.entity,
									},
									CARD.CHECKIN,
								),
							);
						}
					} else {
						dispatch(enableCard(false));
						dispatch(updateActionSheet(false));
						dispatch(enableQuestionnairePage(true, data.booking, 'nextBooking'));
						history.push(ROUTES.QUESTIONNER_PAGE);
					}
				} else if (officeObject?.biro?.healthQuestionnaire?.promptCompletion === 'onCheckIn') {
					dispatch(enableCard(false));
					dispatch(updateActionSheet(false));
					dispatch(enableQuestionnairePage(true, data.booking, 'nextBooking'));
					history.push(ROUTES.QUESTIONNER_PAGE);
				}
			} else {
				if (officeObject?.ceylon?.enabled && !validCeylon && officeObject?.ceylon?.rule !== 'none') {
					history.push(ROUTES.NO_CERTIFICATE_FOR_CHECKIN);
				} else {
					dispatch(loadBooking({...data.booking, teakEntity: data.entity}, CARD.CHECKIN));
				}
			}
		}
	};

	function millisecondsToHoursAndMinutes(milliseconds) {
		const diffObj = Duration.fromMillis(milliseconds).shiftTo('hours', 'minutes', 'seconds').toObject();
		return {hours: Math.abs(diffObj.hours), minutes: Math.abs(diffObj.minutes)};
	}

	useEffect(() => {
		if (timeLeftUntilCheckin <= 0) return;
		const interval = setInterval(() => {
			setTimeLeftUntilCheckin((timeLeftUntilCheckin) => timeLeftUntilCheckin - 60000);
		}, 60000);
		return () => clearInterval(interval);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setTimeLeftUntilCheckin(Math.abs(timeLeftUntilCheckin));
	}, [timeLeftUntilCheckin]);

	const hoursAndMinutes = millisecondsToHoursAndMinutes(timeLeftUntilCheckin);

	useEffect(() => {
		const bookingStartTime = data?.booking?.start?.time;
		const currentTime = DateTime.now().toUTC();

		if (data.entity?.teakSharedOptions?.onlyCheckIns === true) return false; //for an only checkin entity
		if (data.booking === null) return true; //for a other empty places

		const earlyCheckInTimeBeforeStart = teakTypeObj?.options?.bookings?.earlyCheckIn?.timeBeforeStart;
		const startTimeCheckin = DateTime.fromISO(bookingStartTime)
			.toUTC()
			.minus(earlyCheckInTimeBeforeStart ? earlyCheckInTimeBeforeStart : Math.abs(limit.checkinInAdvance * 60000));
		if (startTimeCheckin > DateTime.now().toUTC()) {
			setTimeLeftUntilCheckin(currentTime.diff(startTimeCheckin).toObject().milliseconds);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeLeftUntilCheckin]);

	const isCheckInOpen = () => {
		const bookingStartTime = data?.booking?.start?.time;
		const currentTime = DateTime.now().toUTC();

		if (data.entity?.teakSharedOptions?.onlyCheckIns === true) return false; //for an only checkin entity
		if (data.booking === null) return true; //for a other empty places

		if (!data.booking) return false;

		if (bookingDataStatus === bookingStatus.checkedIn || bookingDataStatus === bookingStatus.autoCheckedIn) {
			if (data.booking?.attendees?.some((attendee) => attendee.member.id === userData._id && attendee?.checkIn)) return false;
		}
		const earlyCheckInEnabled = teakTypeObj?.options?.bookings?.earlyCheckIn?.enabled;
		const earlyCheckInTimeBeforeStart = teakTypeObj?.options?.bookings?.earlyCheckIn?.timeBeforeStart;
		const startTimeCheckin = DateTime.fromISO(bookingStartTime)
			.toUTC()
			.minus(earlyCheckInTimeBeforeStart ? earlyCheckInTimeBeforeStart : Math.abs(limit.checkinInAdvance * 60000));
		const disableCheckin = earlyCheckInEnabled
			? DateTime.fromISO(bookingStartTime).toUTC() > DateTime.now().toUTC().plus(earlyCheckInTimeBeforeStart)
			: DateTime.fromISO(bookingStartTime).toUTC() > DateTime.now().toUTC();
		if (startTimeCheckin > DateTime.now().toUTC()) {
			setTimeLeftUntilCheckin(currentTime.diff(startTimeCheckin).toObject().milliseconds);
		}
		if (disableCheckin) return false;
		return teakTypeObj?.options?.bookings?.biroButtonCheckIn?.enabled;
	};

	const handleCancelAttendance = () => {
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.CANCEL_ATTENDANCE,
				{
					headerKey: 'confirmDelete.confirmCancelAttendance',
					confirmButtonKey: 'common.cancelAttendance',
				},
				data,
			),
		);
	};

	const bookAgain = (e) => {
		e.stopPropagation();
		props.closeActionSheet();
		let seeDetailsData = {
			booking: data.booking,
			entity: getTeakEntitiesFull(areasFromStore, featureTranslations, userData, teakFeaturesFromStore, [data.entity])[0],
		};
		dispatch(addRepeatBookingObject(seeDetailsData));
		history.push(ROUTES.REPEAT_BOOKING);
	};

	const extendBooking = () => {
		dispatch(addDataToCard(data));
		dispatch(enableCard(true));
		dispatch(updateCard(CARD.EXTEND_TIME));
	};

	const cancelBooking = () => {
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.CANCEL_BOOKING,
				{
					headerKey: 'confirmDelete.confirmMessage',
					confirmButtonKey: 'common.cancelBooking',
					infoKey: data?.booking?.pineInvitation && 'confirmDelete.confirmDeleteBookingWithPineInvitation',
				},
				{...data, organizationData: authData?.organizationData},
			),
		);
	};

	const cancelAllRecurringBookings = () => {
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.CANCEL_BOOKING_OCCURENCE,
				{
					headerKey: 'yourBooking.recurringBookings.confirmDeleteOccurences',
					confirmButtonKey: 'yourBooking.recurringBookings.deleteOccurences',
				},
				data,
			),
		);
	};

	const deleteAllRecurringBookings = () => {
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.DELETE_RECURRING_BOOKING,
				{
					headerKey: 'confirmDelete.confirmDeleteRecurringBooking',
					confirmButtonKey: 'common.deleteBooking',
				},
				data,
			),
		);
	};

	const deleteBooking = () => {
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.DELETE_BOOKING,
				{
					headerKey: 'confirmDelete.confirmDeleteBooking',
					confirmButtonKey: 'common.deleteBooking',
				},
				data,
			),
		);
	};

	const editBooking = () => {
		const booking = data?.booking;

		if (booking?.attendees?.length > 0) {
			const filteredAttendees = filteredAttendeeList(booking.attendees, booking, userData);
			dispatch(updateCheckedList(filteredAttendees.map((attendee) => attendee?.member)));
			dispatch(updateAttendeeList(filteredAttendees));
		} else {
			//in case of case of no attendees need to clear list
			dispatch(updateCheckedList([]));
			dispatch(updateAttendeeList([]));
		}

		dispatch(updateEditBookingView());
		history.push(ROUTES.EDIT_BOOKING, {booking: data.booking});
	};

	const goToReportProblem = () => {
		dispatch(enableCard(false));

		if (teakTypeObj.__t === TEAK_TYPE_NAMES.DESK) {
			dispatch(changeDesk(data.entity._id, teakEntityName));
			dispatch(changeArea(areaId, areaName));
			history.push(ROUTES.REPORT_PROBLEM + ROUTES.DESK_PROBLEM_DETAILS);
		} else if (teakTypeObj.__t === TEAK_TYPE_NAMES.ROOM) {
			dispatch(changeRoom(data.entity._id, teakEntityName));
			dispatch(changeArea(areaId, areaName));
			history.push(ROUTES.REPORT_PROBLEM + ROUTES.MEETING_ROOM_PROBLEM_DETAILS);
		} else {
			dispatch(changeArea(areaId, areaName));
			history.push(ROUTES.REPORT_PROBLEM + ROUTES.OTHER_PROBLEM);
		}
	};

	function renderReportProblemButton() {
		if (officeObject?.issueReporting?.enabled && teakTypeForOffice?.office === officeObject?._id) {
			return (
				<div className="action-sheet-option" onClick={goToReportProblem}>
					{t('common.reportProblem')}
				</div>
			);
		}
	}

	useEffect(() => {
		if (!data.booking) return false;
		let booking = data.booking;
		if (booking.status === bookingStatus.checkedIn || booking.status === bookingStatus.autoCheckedIn) {
			if (booking?.attendees?.length > 0) {
				setIsUserCheckedIn(booking?.attendees?.some((attendee) => attendee.member.id === userData._id && attendee?.checkIn));
			} else {
				setIsUserCheckedIn(true);
			}
		} else {
			setIsUserCheckedIn(false);
		}
		setCheckinButtonDisabled(!isCheckInOpen());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	// show menu items

	const showSeeDetails = () => {
		return !isUserCheckedIn;
	};

	const showForward = () => {
		return teakTypeObj.__t === TEAK_TYPE_NAMES.ROOM
	}

	const showShowOnMap = () => {
		return (
			officeId === selectedOffice.id &&
			(bookingDataStatus === bookingStatus.confirmed ||
				bookingDataStatus === bookingStatus.checkInOpen ||
				bookingDataStatus === bookingStatus.checkedIn ||
				bookingDataStatus === bookingStatus.autoCheckedIn)
		);
	};

	const showCheckout = () => {
		return (bookingDataStatus === bookingStatus.checkedIn || bookingDataStatus === bookingStatus.autoCheckedIn) && isUserCheckedIn;
	};

	const showCheckin = () => {
		return (
			bookingDataStatus === bookingStatus.confirmed ||
			bookingDataStatus === bookingStatus.checkInOpen ||
			data.booking === null ||
			((bookingDataStatus === bookingStatus.checkedIn || bookingDataStatus === bookingStatus.autoCheckedIn) && !isUserCheckedIn)
		);
	};

	const showCancelAttendance = () => {
		return !isUserCheckedIn && !isMemberHost;
	};

	const showBookAgain = () => {
		return (bookingDataStatus === bookingStatus.checkedIn || bookingDataStatus === bookingStatus.autoCheckedIn) && isUserCheckedIn;
	};

	const showExtendBooking = () => {
		return (
			(bookingDataStatus === bookingStatus.checkedIn || bookingDataStatus === bookingStatus.autoCheckedIn) && isUserCheckedIn && isMemberHost
		);
	};

	const showBook = () => {
		return (
			data.booking === null ||
			bookingDataStatus === bookingStatus.cancelled ||
			bookingDataStatus === bookingStatus.expired ||
			bookingDataStatus === bookingStatus.checkedOut ||
			bookingDataStatus === bookingStatus.checkOutForgotten
		);
	};

	const showCancelBooking = () => {
		return (bookingDataStatus === bookingStatus.checkInOpen || bookingDataStatus === bookingStatus.confirmed) && !isUserCheckedIn && isMemberHost;
	};

	const showEditBooking = () => {
		return (bookingDataStatus === bookingStatus.checkInOpen || bookingDataStatus === bookingStatus.confirmed) && !isUserCheckedIn && isMemberHost;
	};
	const showCancelAllRecurringBookings = () => {
		return (
			data.booking?.seriesId &&
			isMemberHost &&
			(bookingDataStatus === bookingStatus.checkInOpen || bookingDataStatus === bookingStatus.confirmed)
		);
	};
	const showDeleteAllRecurringBookings = () => {
		return (
			data.booking?.seriesId &&
			isMemberHost &&
			(bookingDataStatus === bookingStatus.cancelled ||
				bookingDataStatus === bookingStatus.expired ||
				bookingDataStatus === bookingStatus.checkOutForgotten ||
				bookingDataStatus === bookingStatus.checkedOut)
		);
	};

	const showDeleteBooking = () => {
		return (
			isMemberHost &&
			(bookingDataStatus === bookingStatus.cancelled ||
				bookingDataStatus === bookingStatus.expired ||
				bookingDataStatus === bookingStatus.checkOutForgotten ||
				bookingDataStatus === bookingStatus.checkedOut)
		);
	};

	return (
		<>
			{showSeeDetails() && (
				<div className="action-sheet-option" onClick={redirectToBookingDetails}>
					{t('common.bookingDetails')}
				</div>
			)}

			{showForward() && (
				<div className="action-sheet-option" onClick={handleForwardClick}>
					{t('common.forward')}
				</div>
			)}

			{showCheckout() && (
				<div className="action-sheet-option" onClick={handleCheckOutClick}>
					{t('common.checkout')}
				</div>
			)}

			{showShowOnMap() && (
				<div className="action-sheet-option" onClick={handleShowOnMap}>
					{t('common.showOnMap')}
				</div>
			)}

			{showCheckin() && (
				<div className={checkinButtonDisabled ? 'action-sheet-option-disabled' : 'action-sheet-option'} onClick={handleCheckInClick}>
					{t('common.checkin')}
					{timeLeftUntilCheckin < 0
						? ' ' +
							t('common.in') +
							' ' +
							(hoursAndMinutes.hours > 9 ? hoursAndMinutes.hours : '0' + hoursAndMinutes.hours) +
							t('common.hoursLetter') +
							':' +
							(hoursAndMinutes.minutes > 9 ? hoursAndMinutes.minutes : '0' + hoursAndMinutes.minutes) +
							t('common.minutesLetter')
						: null}
				</div>
			)}

			{showCancelAttendance() && (
				<div className="action-sheet-option" onClick={handleCancelAttendance}>
					{t('common.cancelAttendance')}
				</div>
			)}

			{showBookAgain() && (
				<div className="action-sheet-option" onClick={bookAgain}>
					{t('common.bookAgain')}
				</div>
			)}

			{showExtendBooking() && (
				<div className="action-sheet-option" onClick={extendBooking}>
					{t('common.extendBooking')}
				</div>
			)}

			{showBook() && (
				<div className="action-sheet-option" onClick={bookAgain}>
					{t('common.book')}
				</div>
			)}

			{showEditBooking() && (
				<div className="action-sheet-option" onClick={editBooking}>
					{t('common.editBooking')}
				</div>
			)}

			{showCancelBooking() && (
				<div className="action-sheet-option" onClick={cancelBooking}>
					{t('common.cancelBooking')}
				</div>
			)}

			{showDeleteAllRecurringBookings() && (
				<div className="action-sheet-option" onClick={deleteAllRecurringBookings}>
					{t('common.deleteAllRecurringBookings')}
				</div>
			)}

			{showCancelAllRecurringBookings() && (
				<div className="action-sheet-option" onClick={cancelAllRecurringBookings}>
					{t('common.cancelAllRecurringBookings')}
				</div>
			)}

			{showDeleteBooking() && (
				<div className="action-sheet-option" onClick={deleteBooking}>
					{t('common.deleteBooking')}
				</div>
			)}

			{renderReportProblemButton()}
		</>
	);
};

export default BookingOptionsMenu;
